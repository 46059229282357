/*eslint-disable*/
import React, { useRef, useState } from 'react';
import { addRoomtypeOffer, checkOfferRates, updateRoomtypeOffer } from '../../../store/actions';
import { useSelector } from 'react-redux';
import { Box, Button, Checkbox, CircularProgress, Divider, FormControlLabel, IconButton, InputAdornment, MenuItem, Modal, Paper, Radio, RadioGroup, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { CssTextField } from '../../../components/TextField';
import { Add, ArrowBack, CalendarMonth, Cancel } from '@mui/icons-material';
import moment from 'moment';

const startIcon = {
    startAdornment: (
        <InputAdornment position="start">
            <CalendarMonth fontSize='small' sx={{ cursor: "pointer" }} />
        </InputAdornment>
    ),
}

function AddNewRate({ dispatch, colors, roomtypeId, setIsAdding, updatingData }) {
    const types = [
        { name: "Occupancy based", value: "occupancy_based", visible: localStorage?.getItem("isRevenueManager") === "true" ? true : false },
        { name: "Seasonal", value: "seasonal", visible: true },
        { name: "Weekdays", value: "weekdays", visible: true },
        { name: "Fixed rate", value: "fixed_rate", visible: true },
        // { name: "Demand based", value: "demand_based", visible: true },
        // { name: "Promotional", value: "promotional", visible: true },
    ]
    const initialValues = {
        startDate: updatingData?.start_date ? moment.unix(updatingData?.start_date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
        endDate: updatingData?.end_date ? moment.unix(updatingData?.end_date).format("YYYY-MM-DD") : moment().add(1, "days").format("YYYY-MM-DD"),
        type: updatingData?.type ?? "occupancy_based",
        fixedRate: updatingData?.fixed_rate ?? 0,
        percentage: updatingData?.percentage ?? 0,
        standardRate: updatingData?.standard_rate ?? 0,
        childRate: updatingData?.child_rate ?? 0,
        singleOccupancyRate: updatingData?.single_occupancy_rate ?? 0,
        maxOccupancyRate: updatingData?.max_occupancy_rate ?? 0,
        extraBedRate: updatingData?.extra_bed_rate ?? 0,
        numOfDays: updatingData?.no_of_days ?? 0,
    }
    const initialPerc = {
        percentage: 99,
        standard_rate: 0,
        child_rate: 0,
        single_occupancy_rate: 0,
        max_occupancy_rate: 0,
        extra_bed_rate: 0,
        plus_percentage: "",
        minus_percentage: "",
        plusOrMinus: "minus",
    }
    const updatingPercArray = updatingData?.percentages?.map(e => ({...e, plusOrMinus: e.plus_percentage > 0 ? "plus" : "minus"}))
    const startInputRef = useRef();
    const endInputRef = useRef();
    const [values, setValues] = useState(initialValues);
    const [percentages, setPercentages] = useState(updatingData?.percentages?.length ? updatingPercArray : [initialPerc]);
    const [temp, setTemp] = useState("");
    const [weekdays, setSelectedWeekdays] = useState(updatingData?.weekdays?.length ? updatingData?.weekdays : []);
    const [isRateFixed, setIsRateFixed] = useState(false);
    const [isRateIncrement, setIsRateIncrement] = useState(false);
    const [rates, setRates] = useState([]);
    const [ratesModal, setRatesModal] = useState(false);

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { sm: "fit-content", xs: "90%" },
        // maxHeight: "80vh",
        bgcolor: colors.primary[400],
        outline: "none",
        boxShadow: 24,
        p: 2,
    };

    const { isLoading } = useSelector(({ loading }) => ({ isLoading: loading }));
    // console.log(offersList);

    const handleChange = (event) => {
        let fieldname = event?.target?.name;
        let data = values;
        data[fieldname] = event?.target?.value;
        setTemp(event.target.value);
        setValues(data);
    }
    const handleNewFieldBtn = () => {
        if(percentages[percentages.length - 1].percentage == 0){
            return
        }
        var joined = percentages.concat({ ...initialPerc, percentage: percentages[percentages.length - 1].percentage - 1 });
        setPercentages(joined)
    }
    const removeField = (index) => {
        if (percentages?.length > 1) {
            var arr = percentages;
            var filtered = arr.filter((e, i) => i !== index);
            setPercentages(filtered)
        }
    }
    const handleNewFieldValueChange = (i, event) => {
        var valueAdd = percentages;
        const field = event.target.name;
        if (event?.target) {
            valueAdd[i][field] = event.target.value;
        } else {
            valueAdd[i][field] = event
        }

        if(event.target.name === "plus_percentage"){
            valueAdd[i].minus_percentage = 0;
        }else if (event.target.name === "minus_percentage"){
            valueAdd[i].plus_percentage = 0;
        }
        setTemp(event.target.value);
        setPercentages(valueAdd)
    }
    const handlePlusOrMinusChange = (i, val) => {
        var valueAdd = percentages;
        const field = "plusOrMinus";
        valueAdd[i][field] = val;

        if(val === "plus"){
            valueAdd[i].minus_percentage = 0;
        }else if (val === "minus"){
            valueAdd[i].plus_percentage = 0;
        }
        setTemp(val);
        setPercentages(valueAdd)
    }
    
    const handleSubmit = async(e) => {
        e.preventDefault();
        let data = {
            ...values,
            startDate: moment(values.startDate, "YYYY-MM-DD").unix(),
            endDate: moment(values.endDate, "YYYY-MM-DD").unix(),
            roomtypeId,
            isRateFixed,
            isRateIncrement,
            weekdays,
        }
        if (!["seasonal", "weekdays", "fixed_rate"].includes(values?.type)) {
            data.percentages = percentages.map(({plusOrMinus, ...rest}) => rest);
        }
        if (!isRateFixed) {
            data.fixedRate = 0;
        } else {
            data.percentage = 0;
        }
        if(updatingData){
            data.id = updatingData?._id;
        }
        if(data.type === "seasonal") {
            data.weekdays = [];
        }
        console.log(data);
        await dispatch(updatingData ? updateRoomtypeOffer(data) : addRoomtypeOffer(data));
        setValues(initialValues);
        setIsAdding(false);
        // updatingData ? window.location.reload() : null;
    }
    const handleCheckRatesButton = async(val) => {
        if(val.plus_percentage == 0 && val.minus_percentage == 0){
            return
        }
        let data = {
            roomtypeId,
            startDate: moment(values.startDate, "YYYY-MM-DD").unix(),
            endDate: moment(values.endDate, "YYYY-MM-DD").unix(),
            plusPercentage: val.plus_percentage || 0,
            minusPercentage: val.minus_percentage || 0,
        };
        let rates = await dispatch(checkOfferRates(data));
        if(rates){
            // console.log(rates);
            setRates(rates);
            setRatesModal(true);
        }
    }

    return (
        <Box>
            <Stack
                direction={"row"}
                alignItems={"end"}
                spacing={1}
                mb={3}
            >
                <Button size='small' variant='outlined' onClick={() => {
                    setIsAdding(false)
                }}>
                    <ArrowBack fontSize='small' />
                </Button>
                <Typography color={colors.grey[100]} fontWeight="bold" variant='h4'>
                    {updatingData ? "UPDATE" : "ADD NEW"} RATE
                </Typography>
            </Stack>
            <form onSubmit={handleSubmit}>
                <Stack spacing={2}>
                    <Stack direction="row" spacing={1}>
                        <CssTextField
                            fullWidth
                            required
                            size="small"
                            type="date"
                            label="start date"
                            placeholder="Start date"
                            name="startDate"
                            value={moment(values.startDate).format("YYYY-MM-DD")}
                            inputProps={{ min: moment().format("YYYY-MM-DD"), max: moment().add(720, "days").format("YYYY-MM-DD") }}
                            onChange={handleChange}
                            inputRef={startInputRef}
                            onClick={() => startInputRef.current.showPicker()}
                            InputProps={startIcon}
                        />
                        <CssTextField
                            fullWidth
                            required
                            size="small"
                            type="date"
                            label="End date"
                            placeholder="End date"
                            name="endDate"
                            value={moment(values.endDate).format("YYYY-MM-DD")}
                            inputProps={{ min: moment(values.startDate).format("YYYY-MM-DD"), max: moment().add(720, "days").format("YYYY-MM-DD") }}
                            onChange={handleChange}
                            inputRef={endInputRef}
                            onClick={() => endInputRef.current.showPicker()}
                            InputProps={startIcon}
                        />
                    </Stack>
                    <CssTextField
                        fullWidth
                        select
                        size="small"
                        label="Rate type"
                        name="type"
                        value={values.type}
                        onChange={handleChange}
                    >
                        {types?.map((type) => (
                            <MenuItem value={type.value} key={type.value} style={{ display: type.visible ? "" : "none" }}>
                                {type.name}
                            </MenuItem>
                        ))}
                    </CssTextField>
                    <Divider />
                    {values?.type === "weekdays" ? (
                        <CssTextField
                            fullWidth
                            select
                            size="small"
                            label="Weekdays"
                            value={weekdays}
                            SelectProps={{ multiple: true }}
                            onChange={(e) => setSelectedWeekdays(e.target.value)}
                            required={values?.type === "weekdays"}
                        >
                            {moment?.weekdays()?.map((day, i) => (
                                <MenuItem value={day} key={i}>
                                    <Checkbox sx={{ p: "0 5px" }} checked={weekdays.indexOf(day) > -1} />
                                    {day}
                                </MenuItem>
                            ))}
                        </CssTextField>
                    ) : null}
                    {/* {values?.type === "occupancy_based" ? (
                        <CssTextField
                            fullWidth
                            required
                            size="small"
                            type="number"
                            label="Rate starts before (number of days)"
                            placeholder="Rate starts before (number of days)"
                            name="numOfDays"
                            value={values?.numOfDays}
                            onChange={handleChange}
                            inputProps={{ min: 0 }}
                        />
                    ) : null} */}
                    {["seasonal", "weekdays", "fixed_rate"].includes(values?.type) ?
                        <>
                            <CssTextField
                                fullWidth
                                required
                                size="small"
                                type="number"
                                label="Double occupancy rate"
                                placeholder="Double occupancy rate"
                                name="standardRate"
                                value={values?.standardRate || ""}
                                onChange={handleChange}
                                inputProps={{ min: 0 }}
                            />
                            <CssTextField
                                fullWidth
                                required
                                size="small"
                                type="number"
                                label="Triple occupancy rate"
                                placeholder="Triple occupancy rate"
                                name="maxOccupancyRate"
                                value={values?.maxOccupancyRate || ""}
                                onChange={handleChange}
                                inputProps={{ min: 0 }}
                            />
                            <CssTextField
                                fullWidth
                                required
                                size="small"
                                type="number"
                                label="Single occupancy rate"
                                placeholder="Single occupancy rate"
                                name="singleOccupancyRate"
                                value={values?.singleOccupancyRate || ""}
                                onChange={handleChange}
                                inputProps={{ min: 0 }}
                            />
                            <CssTextField
                                fullWidth
                                required
                                size="small"
                                type="number"
                                label="Extra bed rate"
                                placeholder="Extra bed rate"
                                name="extraBedRate"
                                value={values?.extraBedRate || ""}
                                onChange={handleChange}
                                inputProps={{ min: 0 }}
                            />
                            <CssTextField
                                fullWidth
                                required
                                size="small"
                                type="number"
                                label="Child rate"
                                placeholder="Child rate"
                                name="childRate"
                                value={values?.childRate || ""}
                                onChange={handleChange}
                                inputProps={{ min: 0 }}
                            />
                        </>
                        : (
                            <>
                                {percentages?.map((el, idx) => (
                                    <Stack
                                        key={idx}
                                        p={2}
                                        spacing={2}
                                        borderRadius={2}
                                        bgcolor={colors.primary[400]}
                                    >
                                        <Stack alignItems="end" m={-1}>
                                            <IconButton size='small' onClick={() => removeField(idx)}>
                                                <Cancel />
                                            </IconButton>
                                        </Stack>
                                        <CssTextField
                                            fullWidth
                                            required
                                            size="small"
                                            type="number"
                                            label="Percentage"
                                            placeholder="Percentage"
                                            name="percentage"
                                            value={el?.percentage ?? ""}
                                            onChange={(newValue) => handleNewFieldValueChange(idx, newValue)}
                                            inputProps={{ min: 0, max: idx === 0 ? 99 : percentages[idx - 1].percentage - 1 }}
                                            helperText={`if ${el.percentage}% - ${idx === 0 ? "100" : percentages[idx - 1].percentage}% room is available, rate will ${el.plusOrMinus === "plus" ? "increase" : "decrease"}`}
                                        />
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                        <Typography>
                                            Current rate
                                        </Typography>
                                        <RadioGroup
                                            row
                                            name="plusOrMinus"
                                            value={el?.plusOrMinus}
                                            onChange={() => handlePlusOrMinusChange(idx, el.plusOrMinus === "plus" ? "minus" : "plus")}
                                        >
                                            <FormControlLabel value="plus" control={<Radio />} label="Plus" />
                                            <FormControlLabel value="minus" control={<Radio />} label="Minus" />
                                        </RadioGroup>
                                        </Stack>
                                        <CssTextField
                                            fullWidth
                                            required
                                            size="small"
                                            type="number"
                                            label={"Percentage"}
                                            placeholder={"Percentage"}
                                            name={el?.plusOrMinus === "plus" ? "plus_percentage" : "minus_percentage"}
                                            value={el?.plusOrMinus === "plus" ? el?.plus_percentage : el?.minus_percentage}
                                            onChange={(newValue) => handleNewFieldValueChange(idx, newValue)}
                                            inputProps={{ min: 1, max: 100 }}
                                        />
                                        <Stack alignItems="start">
                                            <Button
                                                size='small'
                                                variant='contained'
                                                onClick={() => handleCheckRatesButton(el)}
                                                disabled={el.plus_percentage == 0 && el.minus_percentage == 0}
                                            >
                                                Check rates
                                            </Button>
                                        </Stack>
                                        {/* <CssTextField
                                            fullWidth
                                            required
                                            size="small"
                                            type="number"
                                            label="Double occupancy rate"
                                            placeholder="Double occupancy rate"
                                            name="standard_rate"
                                            value={el?.standard_rate ?? ""}
                                            onChange={(newValue) => handleNewFieldValueChange(idx, newValue)}
                                            inputProps={{ min: 0 }}
                                        />
                                        <CssTextField
                                            fullWidth
                                            required
                                            size="small"
                                            type="number"
                                            label="Triple occupancy rate"
                                            placeholder="Triple occupancy rate"
                                            name="max_occupancy_rate"
                                            value={el?.max_occupancy_rate ?? ""}
                                            onChange={(newValue) => handleNewFieldValueChange(idx, newValue)}
                                            inputProps={{ min: 0 }}
                                        />
                                        <CssTextField
                                            fullWidth
                                            required
                                            size="small"
                                            type="number"
                                            label="Single occupancy rate"
                                            placeholder="Single occupancy rate"
                                            name="single_occupancy_rate"
                                            value={el?.single_occupancy_rate || ""}
                                            onChange={(newValue) => handleNewFieldValueChange(idx, newValue)}
                                            inputProps={{ min: 0 }}
                                        />
                                        <CssTextField
                                            fullWidth
                                            required
                                            size="small"
                                            type="number"
                                            label="Extra bed rate"
                                            placeholder="Extra bed rate"
                                            name="extra_bed_rate"
                                            value={el?.extra_bed_rate || ""}
                                            onChange={(newValue) => handleNewFieldValueChange(idx, newValue)}
                                            inputProps={{ min: 0 }}
                                        />
                                        <CssTextField
                                            fullWidth
                                            required
                                            size="small"
                                            type="number"
                                            label="Child rate"
                                            placeholder="Child rate"
                                            name="child_rate"
                                            value={el?.child_rate || ""}
                                            onChange={(newValue) => handleNewFieldValueChange(idx, newValue)}
                                            inputProps={{ min: 0 }}
                                        /> */}
                                    </Stack>
                                ))}
                                <Button fullWidth size='small' onClick={handleNewFieldBtn} disabled={percentages[percentages.length - 1].percentage == 0}>
                                    <Add /> <b>Add new percentage</b>
                                </Button>
                            </>
                        )}
                    <Stack direction="row" justifyContent="space-between" spacing={2}>
                        <Button variant="outlined" onClick={() => setIsAdding(false)}>
                            <b>Cancel</b>
                        </Button>
                        <Button type="submit" variant="contained">
                            {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Submit</b>}
                        </Button>
                    </Stack>
                </Stack>
            </form>

            <Modal
                open={ratesModal}
                onClose={() => setRatesModal(false)}
            >
                <Box sx={modalStyle}>
                    <Box sx={{ maxHeight: "80vh", overflow: "auto" }}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: "fit-content" }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell align="right">Double occupancy rate</TableCell>
                                        <TableCell align="right">Triple occupancy rate</TableCell>
                                        <TableCell align="right">Single occupancy rate</TableCell>
                                        {/* <TableCell align="right">Extra bed rate</TableCell>
                                        <TableCell align="right">Child rate</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rates.map((row) => (
                                        <TableRow
                                            key={row.date}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" sx={{ whiteSpace: "nowrap" }}>
                                                {row.date}
                                            </TableCell>
                                            <TableCell align="right">{row.standard_rate}</TableCell>
                                            <TableCell align="right">{row.max_occupancy_rate}</TableCell>
                                            <TableCell align="right">{row.single_occupancy_rate}</TableCell>
                                            {/* <TableCell align="right">{row.extra_bed_rate}</TableCell>
                                            <TableCell align="right">{row.child_rate}</TableCell> */}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Modal>
        </Box>
    )
}

export default AddNewRate;