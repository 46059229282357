import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHotelDetails, listAllBookingEngineActivities, resetAllHotelDetails, resetAvailabilityData } from '../../store/actions';
import { Button, Container, Stack, Typography, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { tokens } from '../../theme';
import Loader from '../../components/Loader';
import Page1 from './page1';
import AvailableRoomtypes from './availableRoomtypes';
import BookNow from './bookNow';
import Airpay from './airpayPG';
import ErrorPage from './errorPage';
import Activities from './activities';
import { CheckBox, Edit } from '@mui/icons-material';
import moment from 'moment';
import CustomConfirmModal from '../../components/CustomConfirmModal';

// const exceptions = ["misty-mountain-plantation-resort"];

function BookingEngine() {
    const { code } = useParams();
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [status, setStatus] = useState(false);
    const [isPrivate, setIsPrivate] = useState(false);
    const [roomtypeId, setRoomtypeId] = useState("");
    const [page, setPage] = useState(1);
    const [errMsg, setErrMsg] = useState("");
    const [paymentData, setPaymentData] = useState({});
    const [activities, setActivities] = React.useState([]);
    const [selectedActivities, setselectedActivities] = React.useState([]);
    const [roomNumber, setRoomNumber] = useState(0);

    // const totalActivitiesPrice = selectedActivities?.reduce((total, item) => total + (item.rate * item.count), 0);

    let datas = localStorage.getItem("checkingData");
    datas = JSON.parse(datas);

    // let selected = localStorage.getItem("selected");
    // selected = JSON.parse(selected);

    let rooms = localStorage.getItem("rooms");
    rooms = JSON.parse(rooms);

    const { isLoading, hotelDetails, availableRoomtypes } = useSelector(
        ({ loading, bookingEngine: { hotelDetails, availableRoomtypes } }) => ({
            isLoading: loading,
            hotelDetails,
            availableRoomtypes,
        })
    );
    // console.log(hotelDetails);
    useEffect(() => {
        document.title = 'Booking';
        dispatch(resetAllHotelDetails());
        dispatch(resetAvailabilityData());
        fetchData();
        fetchActivities();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [page])

    const fetchData = async () => {
        let { status, is_private } = await dispatch(getHotelDetails({ hotelCode: code }));
        if (status === true) {
            setStatus(status);
            setIsPrivate(is_private);
        }
    }

    const fetchActivities = async () => {
        let activitiesData = await dispatch(listAllBookingEngineActivities({ hotelCode: code }));
        if (activitiesData.length) {
            setActivities(activitiesData);
        }
    }

    const RenderStepsBar = ({title = "", content, page, action1 }) => (
        <Stack
            direction="row"
            alignItems={{xs: "start", md:"center"}}
            spacing={1}
            p={1.5}
            mt={0.3}
            borderRadius={2}
            // bgcolor={"rgb(0, 0, 0, 0.7)"}
            bgcolor={colors.primary[400]}
        >
            <Stack>
                <CheckBox color='primary' />
            </Stack>
            <Stack direction={{ xs: "column", md: "row" }} alignItems={{xs: "start", md:"center"}} flexGrow={1}>
                <Typography flexGrow={1} fontSize={20} fontWeight={700}>
                    {title}
                </Typography>
                {content}
            </Stack>
            <Stack>
                <CustomConfirmModal
                    action={() => {
                        setPage(page);
                        if(action1){
                            action1();
                        }
                    }}
                    text='Editing will remove any previous selections from your booking.'
                    submitBtnLabel='Proceed'
                    clickButton={(setModal) => (
                        <Button
                            variant='outlined'
                            size='small'
                            startIcon={<Edit />}
                            sx={{ fontWeight: 600 }}
                            onClick={setModal}
                        >
                            Modify
                        </Button>
                    )}
                />
            </Stack>
        </Stack>
    )

    return (
        <>
        <Loader visible={isLoading} />
        <Stack width="100%" height="100%" style={{
            backgroundImage: `url(${hotelDetails?.hotel_img || "https://assets.cntraveller.in/photos/63b80c6d79d81704e445df00/3:2/w_1920,h_1280,c_limit/Westin%20Himalayas%20facade.jpg"})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
        }}>
            <Stack minHeight={"100vh"} style={{backdropFilter: hotelDetails?.hotel_img && page === 1 ? "" : `blur(10px)`}}>
            {status ? (
                <Container>
                    <Stack pt={2}>
                        {page > 1 ? (
                            <RenderStepsBar 
                                title='1. Search' 
                                content={
                                    <Typography>
                                        {moment.unix(datas?.checkinDate).format("DD-MMM-YYYY")} - {moment.unix(datas?.checkoutDate).format("DD-MMM-YYYY")} | {datas?.totalRooms} Room | {datas?.numOfAdults} Adults | {datas?.numOfChilds} Children
                                    </Typography>
                                } 
                                page={1}
                            />
                        ) : null}
                        {page > 2 ? (
                            <>
                            {rooms.map((room, index) => (
                                <RenderStepsBar
                                    key={index}
                                    title={`${index + 2}. Select Room ${index + 1} (${room.no_of_adults} Adults, ${room.no_of_childs} Children)`}
                                    content={
                                        <Typography>
                                            {room?.roomtype?.name} | {room?.roomtype?.meal_plan?.name} | ₹{room?.roomtype?.meal_plan?.total_price}
                                        </Typography>
                                    }
                                    page={2}
                                    action1={() => setRoomNumber(index)}
                                />
                            ))}

                            {/* <RenderStepsBar 
                                title='2. Select Room' 
                                content={
                                    <Typography>
                                        {selected?.name} | {selected?.meal_plan?.name} {selected?.meal_plan?.description ? `- ${selected?.meal_plan?.description}`: ""} | ₹{selected?.meal_plan?.total_price}
                                    </Typography>
                                    } 
                                page={2}
                            /> */}
                            </>
                        ) : null}
                        {page > 3 && selectedActivities?.length ? (
                            <RenderStepsBar 
                                title={`${rooms.length + 2}. Enhance Your Stay`} 
                                content={
                                    <Stack textAlign="end">
                                    {selectedActivities?.map((activity) => (
                                        <Stack key={activity._id} direction="row" justifyContent="space-between" spacing={1}>
                                        <Typography>{activity?.name}</Typography>
                                        <Typography> - </Typography>
                                        <Typography>{activity.rate} x {activity.count}</Typography>
                                        </Stack>
                                    ))}
                                    </Stack>
                                }
                                page={3}
                            />
                        ) : null}
                    </Stack>

                    {page === 1 && (
                       <Page1 
                            code={code}
                            dispatch={dispatch}
                            isPrivate={isPrivate}
                            colors={colors}
                            setPage={setPage}
                            setErrMsg={setErrMsg}
                            hotelDetails={hotelDetails}
                            isLoading={isLoading}
                        />
                    )}
                    {page === 2 && (
                        <AvailableRoomtypes
                            code={code}
                            roomtypes={availableRoomtypes}
                            dispatch={dispatch}
                            colors={colors}
                            setPage={setPage}
                            roomtypeId={roomtypeId}
                            setRoomtypeId={setRoomtypeId}
                            isLoading={isLoading}
                            hotelDetails={hotelDetails}
                            isActivitiesAvailable={activities.length ? true : false}
                            roomNumber={roomNumber}
                            setRoomNumber={setRoomNumber}
                        />
                    )}
                    {page === 3 && (
                        <Activities
                            activities={activities}
                            setPage={setPage}
                            colors={colors}
                            selectedActivities={selectedActivities}
                            setselectedActivities={setselectedActivities}
                        />
                    )}
                    {page === 3.1 && (
                        <BookNow
                            // roomtypes={availableRoomtypes}
                            code={code}
                            colors={colors}
                            isLoading={isLoading}
                            setPage={setPage}
                            roomtypeId={roomtypeId}
                            dispatch={dispatch}
                            setPaymentData={setPaymentData}
                            isActivitiesAvailable={activities.length ? true : false}
                            selectedActivities={selectedActivities}
                            hotelDetails={hotelDetails}
                        />
                    )}
                    {page === 4 && (
                        <Airpay
                            // code={code}
                            // isLoading={isLoading}
                            // setPage={setPage}
                            // roomtypeId={roomtypeId}
                            // dispatch={dispatch}
                            paymentData={paymentData}
                        />
                    )}
                    {page === 5 && (
                        <ErrorPage
                            msg={errMsg}
                        />
                    )}
                </Container>
            ) : (
                <Stack alignItems="center" justifyContent="center" minHeight={"100vh"}>
                    <Stack p={5} bgcolor={colors.primary[400]}>
                        Property does not exist!
                    </Stack>
                </Stack>
            )}
            </Stack>
        </Stack>
        </>
    )
}

export default BookingEngine